.container {
  display: flex;
  gap: 32px;
  margin-right: 32px;
  margin-bottom: 16px;

  > div {
    width: 100%;
    min-width: 256px;
    max-width: 100%;
  }
}

.title-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  svg {
    color: var(--content-secondary);
  }
}

.tooltip-button {
  width: 100%;
  min-width: 256px;
  max-width: 100%;
  height: unset;
  padding: 0;
  cursor: pointer;
  background: none;
}

.icon-margin {
  margin-right: 10px;
}

.title {
  display: flex;
  gap: 4px;
  align-items: center;
}

.inline-subtitle-divider {
  font-size: 8px;
  color: var(--content-secondary);
}

.inline-subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.amount-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: baseline;
}

.vat-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
